import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import HeroImage from '../components/HeroImage';
import Section from '../components/sections/Section';
import FacebookImg from './assets/FB-f-Logo__blue_58.png';
import HomeImgWebp from './assets/home.webp';
import HomeImgFallback from './assets/home.jpg';
import Layout from '../components/Layout';

import styles from './index.module.css';

const HomePage = () => (
  <Layout>
    <Header title={'Willkommen beim VC-TK'} />
    <HeroImage
      imgUrl={HomeImgFallback}
      webpUrl={HomeImgWebp}
      altText="VC-TK Startseitenbild"
    />
    <Section>
      <article>
        <p>
          Wir sind der größte Volleyballverein der Region
          Kleinmachnow-Teltow-Stahnsdorf und entwickeln uns seit unserer
          Gründung im Jahr 2000 ständig weiter. So stellen wir in der Saison
          2019/20 zwei Herrenmannschaften und auch wieder eine Damenmannschaft.
        </p>
        <p>
          Neben der Leistungsorientierung im Punktspiel- und Turnierbetrieb,
          sollen bei uns aber weder Spaß noch Geselligkeit zu kurz kommen.
          Deshalb führen wir die Tradition aus dem Jahre 1966 fort, die in einem
          jährlichen Wochenendaustausch mit unserer tschechischen
          Partnermannschaft aus Jilemnice besteht. Wenn du Interesse hast selbst
          bei uns zu trainieren/spielen, so kannst du dich über unser
          Kontaktformular an uns wenden.
        </p>
      </article>
    </Section>
    <Section
      inverse
      headline="Uns findet man auch auf..."
      className={styles.socialLinks}
    >
      <a
        href="https://www.facebook.com/vcteltowkleinmachnow/"
        target="vctk-at-facebook"
      >
        <img src={FacebookImg} alt="facebook" />
      </a>
    </Section>
  </Layout>
);

export const pageQuery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark {
      id
      fields {
        slug
      }
      frontmatter {
        title
        order
      }
    }
  }
`;

export default HomePage;
