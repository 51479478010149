import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeroImage.module.css';

const HeroImage = ({ imgUrl, webpUrl, altText }) => (
  <picture>
    {webpUrl && <source type="image/webp" srcSet={webpUrl} />}
    <img className={styles.heroImg} src={imgUrl} alt={altText} />
  </picture>
);

HeroImage.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  webpUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

export default HeroImage;
